<template>
  <div class="page-wrapper">
    <header class="header">
      <div class="logo">
        <svg viewBox="0 0 240 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 32.9654V21.4774C0 15.2242 3.37834 11.8458 9.16754 11.8458C14.9567 11.8458 18.3744 15.2242 18.5868 21.4774H12.5891C12.42 18.7756 11.4054 17.6744 9.12428 17.6744C7.30729 17.6744 6.25328 18.8581 6.25328 21.4774V32.9654C6.25328 35.4982 7.26796 36.682 9.12428 36.682C11.4486 36.682 12.5026 35.5847 12.6717 32.9654H18.6694C18.5435 39.1754 15.1219 42.5105 9.12035 42.5105C3.11877 42.5105 0 39.1715 0 32.9654Z"
            fill="white"
          />
          <path
            d="M22.095 32.4148V22.0241C22.095 15.6882 25.6857 11.9284 31.6833 11.9284C37.681 11.9284 41.2717 15.6882 41.2717 22.0241V32.4148C41.2717 38.7506 37.681 42.5105 31.6833 42.5105C25.6857 42.5105 22.095 38.7506 22.095 32.4148ZM35.0617 32.4148V22.0241C35.0617 19.2357 33.9211 17.7569 31.6833 17.7569C29.4455 17.7569 28.305 19.2357 28.305 22.0241V32.4148C28.305 35.2032 29.4455 36.6819 31.6833 36.6819C33.9211 36.6819 35.0617 35.2032 35.0617 32.4148Z"
            fill="white"
          />
          <path
            d="M71.0986 12.4358V42.0071H65.2268V34.7391C65.396 31.1484 65.5218 27.5577 65.6909 23.967L65.4785 23.8844L62.816 31.6557L61.3372 35.6673H55.4222L53.9434 31.6557L51.2809 23.8844L51.0685 23.967C51.1943 27.5577 51.3634 31.1484 51.4893 34.7391V42.0071H45.6608V12.4358H51.7017L56.3464 23.6287C56.9363 25.1075 57.951 27.6835 58.2892 28.7808H58.4584C58.7966 27.6835 59.8113 25.1075 60.4012 23.6287L65.0459 12.4358H71.0868H71.0986Z"
            fill="white"
          />
          <path
            d="M94.6683 22.4882C94.6683 28.7808 91.0343 32.5013 84.9108 32.5013H82.3348V42.0071H76.3372V12.4358H84.9108C91.0343 12.4358 94.6683 16.1523 94.6683 22.4882ZM88.5016 22.4882C88.5016 19.6172 87.2352 18.1384 84.8282 18.1384H82.3348V26.7986H84.8282C87.2352 26.7986 88.5016 25.3199 88.5016 22.4882Z"
            fill="white"
          />
          <path d="M113.637 36.3005V42.0031H97.9641V12.4358H113.216V18.1384H103.962V24.2619H112.37V29.7522H103.962V36.3005H113.637Z" fill="white" />
          <path d="M154.928 36.3005V42.0031H139.255V12.4358H154.503V18.1384H145.253V24.2619H153.658V29.7522H145.253V36.3005H154.928Z" fill="white" />
          <path
            d="M159.022 12.4358H164.937L170.553 25.2766C171.273 27.1369 171.949 29.0797 172.665 30.936L172.791 30.8102C172.284 27.0936 171.945 22.0241 171.945 19.279V12.4358H177.86V42.0071H171.945L166.962 30.5585L164.3 23.3338L164.174 23.4596C164.638 27.1762 164.933 32.7098 164.933 36.0055V42.0031H159.018V12.4358H159.022Z"
            fill="white"
          />
          <path
            d="M181.455 32.9654V21.4774C181.455 15.2242 184.834 11.8458 190.623 11.8458C196.412 11.8458 199.83 15.2242 200.042 21.4774H194.044C193.875 18.7756 192.861 17.6744 190.579 17.6744C188.762 17.6744 187.705 18.8581 187.705 21.4774V32.9654C187.705 35.4982 188.719 36.682 190.579 36.682C192.904 36.682 193.958 35.5847 194.127 32.9654H200.125C199.999 39.1754 196.577 42.5105 190.579 42.5105C184.582 42.5105 181.455 39.1715 181.455 32.9654Z"
            fill="white"
          />
          <path d="M219.262 36.3005V42.0031H203.589V12.4358H218.837V18.1384H209.587V24.2619H217.995V29.7522H209.587V36.3005H219.262Z" fill="white" />
          <path
            d="M221.543 33.3862H227.71C227.71 35.7538 228.894 36.7645 230.793 36.7645C232.693 36.7645 233.751 35.624 233.751 34.1452C233.751 29.2449 222.176 28.6549 222.176 19.6565C222.176 15.1376 225.346 11.8419 230.793 11.8419C236.24 11.8419 239.536 15.0944 239.536 20.5454H233.369C233.369 18.6025 232.48 17.6311 230.75 17.6311C229.189 17.6311 228.256 18.4767 228.256 19.8296C228.256 24.4782 240 24.7732 240 34.1924C240 39.0495 236.578 42.597 230.707 42.597C224.835 42.597 221.539 39.3445 221.539 33.3862"
            fill="white"
          />
          <path
            d="M106.074 70.725H103.962V80.6516H97.9641V51.0802H106.538C112.41 51.0802 115.874 54.7142 115.874 60.9242C115.874 64.9358 114.396 67.8933 111.776 69.4586L117.353 80.6516H110.805L106.074 70.725ZM103.962 65.0223H106.455C108.567 65.0223 109.708 63.5868 109.708 60.9242C109.708 58.2617 108.567 56.7829 106.455 56.7829H103.962V65.0184V65.0223Z"
            fill="#A1C9DC"
          />
          <path d="M135.857 74.9489V80.6555H120.185V51.0842H135.433V56.7868H126.182V62.9103H134.591V68.4046H126.182V74.9489H135.857Z" fill="#A1C9DC" />
          <path
            d="M139.13 71.2323V51.0842H145.253V71.2323C145.253 73.8949 146.307 75.3304 148.423 75.3304C150.539 75.3304 151.589 73.8949 151.589 71.2323V51.0842H157.712V71.2323C157.712 77.4423 154.248 81.1589 148.419 81.1589C142.59 81.1589 139.126 77.4423 139.126 71.2323"
            fill="#A1C9DC"
          />
          <path
            d="M161.319 72.0346H167.486C167.486 74.4022 168.669 75.4129 170.569 75.4129C172.469 75.4129 173.527 74.2724 173.527 72.7936C173.527 67.8933 161.952 67.3034 161.952 58.3049C161.952 53.786 165.122 50.4903 170.569 50.4903C176.016 50.4903 179.312 53.7428 179.312 59.1938H173.145C173.145 57.2509 172.256 56.2795 170.526 56.2795C168.964 56.2795 168.032 57.1251 168.032 58.474C168.032 63.1227 179.776 63.4177 179.776 72.8369C179.776 77.694 176.354 81.2415 170.482 81.2415C164.611 81.2415 161.315 77.989 161.315 72.0307"
            fill="#A1C9DC"
          />
          <path
            d="M182.525 72.0346H188.692C188.692 74.4022 189.875 75.4129 191.775 75.4129C193.675 75.4129 194.733 74.2724 194.733 72.7936C194.733 67.8933 183.158 67.3034 183.158 58.3049C183.158 53.786 186.328 50.4903 191.775 50.4903C197.222 50.4903 200.518 53.7428 200.518 59.1938H194.351C194.351 57.2509 193.462 56.2795 191.732 56.2795C190.17 56.2795 189.238 57.1251 189.238 58.474C189.238 63.1227 200.982 63.4177 200.982 72.8369C200.982 77.694 197.56 81.2415 191.689 81.2415C185.817 81.2415 182.521 77.989 182.521 72.0307"
            fill="#A1C9DC"
          />
          <path d="M211.026 51.0842H205.029V80.6555H211.026V51.0842Z" fill="#A1C9DC" />
          <path
            d="M231.418 72.6442L229.83 69.4586C232.449 67.8933 233.928 64.9397 233.928 60.9242C233.928 54.7142 230.463 51.0802 224.591 51.0802H216.017V80.6516H222.015V70.725H224.127L226.589 75.8888C227.977 78.7992 230.911 80.6516 234.132 80.6516H239.221V74.9056H235.064C233.519 74.9056 232.103 74.0286 231.415 72.6442M224.504 65.0223H222.011V56.7868H224.504C226.616 56.7868 227.757 58.2223 227.757 60.9282C227.757 63.634 226.616 65.0262 224.504 65.0262"
            fill="#A1C9DC"
          />
          <path
            d="M83.499 74.3078C85.607 74.3078 86.8498 75.5231 86.8498 77.576V78.4373H90.0315V80.447H80.1285V77.576C80.1285 75.5231 81.3713 74.3078 83.4951 74.3078M83.4951 76.3726C82.5315 76.3726 82.036 76.7973 82.036 77.6036V78.4373H84.9345V77.6036C84.9345 76.7973 84.439 76.3726 83.4911 76.3726"
            fill="white"
          />
          <path
            d="M86.8222 73.3167H83.3416C81.2218 73.3167 79.9594 72.1133 79.9594 70.1036C79.9594 68.0939 81.2179 66.8904 83.3416 66.8904H86.8222C88.946 66.8904 90.2045 68.0939 90.2045 70.1036C90.2045 72.1133 88.946 73.3167 86.8222 73.3167ZM86.8222 68.9748H83.3416C82.4095 68.9748 81.914 69.3563 81.914 70.1075C81.914 70.8587 82.4095 71.2402 83.3416 71.2402H86.8222C87.7543 71.2402 88.2499 70.8587 88.2499 70.1075C88.2499 69.3563 87.7543 68.9748 86.8222 68.9748Z"
            fill="white"
          />
          <path
            d="M86.8774 65.5926H80.1285V63.5396H86.8774C87.7701 63.5396 88.2499 63.1856 88.2499 62.4777C88.2499 61.7698 87.7701 61.4158 86.8774 61.4158H80.1285V59.3629H86.8774C88.9578 59.3629 90.2006 60.5231 90.2006 62.4738C90.2006 64.4245 88.9578 65.5847 86.8774 65.5847"
            fill="white"
          />
          <path
            d="M86.1694 55.0682V55.7761H89.4927V57.7858H79.5897V54.9148C79.5897 52.9483 80.805 51.7881 82.8855 51.7881C84.2305 51.7881 85.2177 52.2837 85.7447 53.1607L89.4927 51.2926V53.4832L86.1694 55.0682ZM84.2581 55.7761V54.9423C84.2581 54.2344 83.7782 53.8529 82.8855 53.8529C81.9927 53.8529 81.4972 54.2344 81.4972 54.9423V55.7761H84.2541H84.2581Z"
            fill="white"
          />
          <path d="M109.251 7.61012L112.893 0H100.406L96.7645 7.61012H109.251Z" fill="#FFE932" />
          <path d="M123.268 27.0267H129.435V18.1384H135.22V12.4358H117.479V18.1384H123.268V27.0267Z" fill="white" />
          <path d="M131.464 46.2585H118.977L122.619 38.6484L118.977 31.0383H131.464L135.11 38.6484L131.464 46.2585Z" fill="#FFE932" />
          <path
            d="M106.074 70.725H103.962V80.6516H97.9641V51.0802H106.538C112.41 51.0802 115.874 54.7142 115.874 60.9242C115.874 64.9358 114.396 67.8933 111.776 69.4586L117.353 80.6516H110.805L106.074 70.725ZM103.962 65.0223H106.455C108.567 65.0223 109.708 63.5868 109.708 60.9242C109.708 58.2617 108.567 56.7829 106.455 56.7829H103.962V65.0184V65.0223Z"
            fill="#A1C9DC"
          />
          <path d="M135.857 74.9489V80.6555H120.185V51.0842H135.433V56.7868H126.182V62.9103H134.591V68.4046H126.182V74.9489H135.857Z" fill="#A1C9DC" />
          <path
            d="M139.13 71.2323V51.0842H145.253V71.2323C145.253 73.8949 146.307 75.3304 148.423 75.3304C150.539 75.3304 151.589 73.8949 151.589 71.2323V51.0842H157.712V71.2323C157.712 77.4423 154.248 81.1589 148.419 81.1589C142.59 81.1589 139.126 77.4423 139.126 71.2323"
            fill="#A1C9DC"
          />
          <path
            d="M161.319 72.0346H167.486C167.486 74.4022 168.669 75.4129 170.569 75.4129C172.469 75.4129 173.527 74.2724 173.527 72.7936C173.527 67.8933 161.952 67.3034 161.952 58.3049C161.952 53.786 165.122 50.4903 170.569 50.4903C176.016 50.4903 179.312 53.7428 179.312 59.1938H173.145C173.145 57.2509 172.256 56.2795 170.526 56.2795C168.964 56.2795 168.032 57.1251 168.032 58.474C168.032 63.1227 179.776 63.4177 179.776 72.8369C179.776 77.694 176.354 81.2415 170.482 81.2415C164.611 81.2415 161.315 77.989 161.315 72.0307"
            fill="#A1C9DC"
          />
          <path
            d="M182.525 72.0346H188.692C188.692 74.4022 189.875 75.4129 191.775 75.4129C193.675 75.4129 194.733 74.2724 194.733 72.7936C194.733 67.8933 183.158 67.3034 183.158 58.3049C183.158 53.786 186.328 50.4903 191.775 50.4903C197.222 50.4903 200.518 53.7428 200.518 59.1938H194.351C194.351 57.2509 193.462 56.2795 191.732 56.2795C190.17 56.2795 189.238 57.1251 189.238 58.474C189.238 63.1227 200.982 63.4177 200.982 72.8369C200.982 77.694 197.56 81.2415 191.689 81.2415C185.817 81.2415 182.521 77.989 182.521 72.0307"
            fill="#A1C9DC"
          />
          <path d="M211.026 51.0842H205.029V80.6555H211.026V51.0842Z" fill="#A1C9DC" />
          <path
            d="M231.418 72.6442L229.83 69.4586C232.449 67.8933 233.928 64.9397 233.928 60.9242C233.928 54.7142 230.463 51.0802 224.591 51.0802H216.017V80.6516H222.015V70.725H224.127L226.589 75.8888C227.977 78.7992 230.911 80.6516 234.132 80.6516H239.221V74.9056H235.064C233.519 74.9056 232.103 74.0286 231.415 72.6442M224.504 65.0223H222.011V56.7868H224.504C226.616 56.7868 227.757 58.2223 227.757 60.9282C227.757 63.634 226.616 65.0262 224.504 65.0262"
            fill="#A1C9DC"
          />
        </svg>
      </div>
      <nav class="nav">
        <a v-scroll-to:-100 href="#plateformes">LES PLATEFORMES</a>
        <a v-scroll-to:-100 href="#competences">LES COMPÉTENCES</a>
        <a v-scroll-to:-100 href="#about">À propos</a>
      </nav>
      <a href="#" class="cta" @click.prevent="showpop()">
        <div class="left">
          <svg viewBox="0 0 22 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L21.4945 49L21.5 24.5055V0.00549617Z" fill="#FFE932" />
          </svg>
        </div>
        <div class="center"><p>Inscrivez-vous</p></div>
        <div class="right">
          <svg viewBox="0 0 21 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L9.16492 49L21.0001 24.5055L9.17046 0.00549316Z" fill="#FFE932" />
          </svg>
        </div>
      </a>
      <a href="https://compétences-pour-réussir.ca" target="_blank" class="login">Connexion</a>
    </header>

    <section class="hero">
      <div class="data">
        <h1 class="regular" data-inview="fadeInUp" data-delay="200">Développez vos compétences avec les Compétences pour réussir</h1>
        <div class="arguments" data-inview="fadeInUp" data-delay="400">
          <h2 class="title_black">GAGNEZ EN CONFIANCE</h2>
          <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" fill="#FFE932" />
          </svg>
        </div>
      </div>
      <div class="carrousel hero-carousel" data-inview="slideLeft" data-delay="200">
        <div class="slide slide1 carousel-cell" data-hero-text="GAGNEZ EN CONFIANCE">
          <img src="@/assets/img/hero-photo-empty.png" alt="" />
          <img src="@/assets/img/hero-photo-1.jpg" alt="" />
        </div>
        <div class="slide slide2 carousel-cell" data-hero-text="POUR DE MEILLEURES PERFORMANCES">
          <img src="@/assets/img/hero-photo-empty.png" alt="" />
          <img src="@/assets/img/hero-photo-2.jpg" alt="" />
        </div>
        <div class="slide slide3 carousel-cell" data-hero-text="DÉMARQUEZ-VOUS SUR LE MARCHÉ DU TRAVAIL">
          <img src="@/assets/img/hero-photo-empty.png" alt="" />
          <img src="@/assets/img/hero-photo-3.jpg" alt="" />
        </div>
        <div class="slide slide4 carousel-cell" data-hero-text="BOOSTEZ VOTRE EMPLOYABILITÉ">
          <img src="@/assets/img/hero-photo-empty.png" alt="" />
          <img src="@/assets/img/hero-photo-4.jpg" alt="" />
        </div>
      </div>
    </section>

    <section id="plateformes" class="content">
      <div class="img_text">
        <div class="img" data-inview="revealRight" data-delay="200">
          <img src="@/assets/img/groupe-etudiants.jpg" alt="Compétences pour réussir" />
        </div>
        <div class="text">
          <h3 class="title" data-inview="fadeInUp" data-delay="200">
            Le programme Compétences pour Réussir (CPR) est une initiative du gouvernement du Canada destinée aux travailleurs, aux employeurs, aux
            fournisseurs de formation, ainsi qu'aux gouvernements et aux collectivités.
          </h3>
          <p class="regular" data-inview="fadeInUp" data-delay="400">
            Son objectif est d'améliorer les compétences fondamentales et transférables des personnes qui font partie des communautés francophones en
            situation minoritaire, afin de les préparer adéquatement à chercher un emploi, à le conserver, ainsi qu'à s'adapter et réussir au travail. Le
            programme Compétences pour Réussir met à la disposition des utilisateurs deux plateformes en ligne.
          </p>
        </div>
      </div>
      <div class="description" data-inview="fadeInUp" data-delay="200">
        <div class="title_bloc">
          <h4 class="title">PLATEFORME D’ÉVALUATION</h4>
        </div>
        <p class="regular">
          La plateforme d'évaluation des compétences permet aux utilisateurs de passer différents tests pour évaluer leur niveau de compétences par rapport
          au marché du travail canadien. Les évaluations sont disponibles en français et en anglais.
        </p>
        <ul>
          <li>
            <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" />
            </svg>
            <p>Évaluation en ligne</p>
          </li>
          <li>
            <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" />
            </svg>
            <p>Programme entièrement gratuit</p>
          </li>
          <li>
            <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" />
            </svg>
            <p>Rapport détaillé de vos résultats</p>
          </li>
        </ul>
        <a href="https://xn--comptences-pour-russir-e8bn.ca/" target="_blank" class="cta">
          <div class="left">
            <svg viewBox="0 0 22 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L21.4945 49L21.5 24.5055V0.00549617Z" fill="#FFE932" />
            </svg>
          </div>
          <div class="center"><p>Inscrivez-vous</p></div>
          <div class="right">
            <svg viewBox="0 0 21 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L9.16492 49L21.0001 24.5055L9.17046 0.00549316Z" fill="#FFE932" />
            </svg>
          </div>
        </a>
      </div>
      <div class="description" data-inview="fadeInUp" data-delay="400">
        <div class="title_bloc">
          <h4 class="title">PLATEFORME D’APPRENTISSAGE</h4>
        </div>
        <p class="regular">
          La plateforme de formation offre une vaste bibliothèque d'activités d'apprentissage conçues pour améliorer et développer les compétences de base
          et les compétences transversales des utilisateurs.
        </p>
        <ul>
          <li>
            <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" />
            </svg>
            <p>Plateforme d'apprentissage en ligne accessible gratuitement</p>
          </li>
          <li>
            <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" />
            </svg>
            <p>Un plan d'apprentissage personnalisé en fonction de votre niveau</p>
          </li>
          <li>
            <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" />
            </svg>
            <p>Un choix diversifié d'activités d'apprentissage, classées par compétence et niveau de complexité</p>
          </li>
        </ul>
        <a href="https://lacitecc.brightspace.com/program/12/competences-pour-reussir" target="_blank" class="cta">
          <div class="left">
            <svg viewBox="0 0 22 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L21.4945 49L21.5 24.5055V0.00549617Z" fill="#FFE932" />
            </svg>
          </div>
          <div class="center"><p>Inscrivez-vous</p></div>
          <div class="right">
            <svg viewBox="0 0 21 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L9.16492 49L21.0001 24.5055L9.17046 0.00549316Z" fill="#FFE932" />
            </svg>
          </div>
        </a>
      </div>

      <div class="video_featured">
        <div class="info">
          <div class="img">
            <img src="@/assets/img/marie-christine-gill.jpg" alt="Marie-Christine Gill" />
          </div>
          <div class="data">
            <h5 class="title">
              Initiation aux Compétences<br />
              pour réussir (CPR)
            </h5>
            <p class="regular">Par Marie-Christine Gill<br />Consultante en compétences pour réussir</p>
          </div>
        </div>
        <a href="https://view.genial.ly/649476958744fd00196c90b9" target="_blank" class="play">
          <svg viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M45 0C33.0642 0 21.618 4.74255 13.1805 13.1805C4.743 21.6185 0 33.0651 0 45C0 56.9349 4.74255 68.382 13.1805 76.8195C21.6185 85.257 33.0651 90 45 90C56.9349 90 68.382 85.2575 76.8195 76.8195C85.257 68.3816 90 56.9349 90 45C90 33.0651 85.2575 21.618 76.8195 13.1805C68.3816 4.743 56.9349 0 45 0ZM59.4072 46.2168L37.6245 58.7961C37.1886 59.0457 36.6542 59.0457 36.2183 58.7961C35.7823 58.543 35.5151 58.0789 35.5151 57.5761V32.4256C35.5151 31.9229 35.7823 31.4589 36.2183 31.2057C36.6542 30.9561 37.1885 30.9561 37.6245 31.2057L59.4072 43.7814V43.7849C59.8431 44.0345 60.1103 44.4986 60.1103 45.0014C60.1103 45.5041 59.8431 45.9681 59.4072 46.2178V46.2168Z"
              fill="#FFE932"
            />
          </svg>
        </a>
      </div>
    </section>

    <section class="cta_wrap">
      <h3 class="title">Développez vos compétences avec les Compétences pour réussir</h3>
      <div class="line"></div>
      <div class="arrows">
        <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" />
        </svg>
        <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" />
        </svg>
        <svg class="arrow" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M54.1986 0.0074234L0.0147942 0L15.8088 33.0909L0 66.1744L54.1912 66.1818L70 33.0983L54.1986 0.0074234Z" />
        </svg>
        <a href="#" class="cta darker" @click.prevent="showpop()">
          <div class="left">
            <svg viewBox="0 0 22 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L21.4945 49L21.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center"><p>Inscrivez-vous</p></div>
          <div class="right">
            <svg viewBox="0 0 21 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L9.16492 49L21.0001 24.5055L9.17046 0.00549316Z" />
            </svg>
          </div>
        </a>
      </div>
    </section>

    <section id="competences" class="competences">
      <div class="title_wrap">
        <p class="number" data-inview="fadeInUp" data-delay="200">9</p>
        <h2 class="title" data-inview="fadeInUp" data-delay="400">Compétences pour réussir</h2>
      </div>

      <div class="carrousel infos-slider">
        <div class="carousel-cell">
          <div class="left">
            <svg width="15" height="49" viewBox="0 0 15 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L14.4945 49L14.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center">
            <img src="@/assets/img/icon-competence-1.svg" alt="" />
            <h3 class="title">ADAPTABILITÉ</h3>
            <p class="regular">
              Votre capacité à adapter vos comportements lorsque des changements se produisent, en planifiant, en restant concentrés, en persistant et en
              surmontant les obstacles.
            </p>
            <a href="#" @click.prevent="showpop()">Évaluez vos compétences</a>
          </div>
          <div class="right">
            <svg width="14" height="49" viewBox="0 0 14 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L2.16492 49L14.0001 24.5055L2.17046 0.00549316Z" />
            </svg>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <svg width="15" height="49" viewBox="0 0 15 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L14.4945 49L14.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center">
            <img src="@/assets/img/icon-competence-2.svg" alt="" />
            <h3 class="title">COLLABORATION</h3>
            <p class="regular">Votre capacité à contribuer et à soutenir les autres pour atteindre un objectif commun.</p>
            <a href="#" @click.prevent="showpop()">Évaluez vos compétences</a>
          </div>
          <div class="right">
            <svg width="14" height="49" viewBox="0 0 14 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L2.16492 49L14.0001 24.5055L2.17046 0.00549316Z" />
            </svg>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <svg width="15" height="49" viewBox="0 0 15 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L14.4945 49L14.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center">
            <img src="@/assets/img/icon-competence-3.svg" alt="" />
            <h3 class="title">COMMUNICATION</h3>
            <p class="regular">
              Votre capacité à recevoir, à comprendre, à considérer et à partager des renseignements et des idées en parlant, en écoutant et en
              interagissant avec les autres.
            </p>
            <a href="#" @click.prevent="showpop()">Évaluez vos compétences</a>
          </div>
          <div class="right">
            <svg width="14" height="49" viewBox="0 0 14 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L2.16492 49L14.0001 24.5055L2.17046 0.00549316Z" />
            </svg>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <svg width="15" height="49" viewBox="0 0 15 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L14.4945 49L14.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center">
            <img src="@/assets/img/icon-competence-4.svg" alt="" />
            <h3 class="title">CRÉATIVITÉ ET INNOVATION</h3>
            <p class="regular">
              Votre capacité à imaginer, à développer, à exprimer, à encourager et à appliquer des idées de manière novatrice, inattendue ou de remettre en
              question les méthodes et les normes existantes.
            </p>
            <a href="#" @click.prevent="showpop()">Évaluez vos compétences</a>
          </div>
          <div class="right">
            <svg width="14" height="49" viewBox="0 0 14 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L2.16492 49L14.0001 24.5055L2.17046 0.00549316Z" />
            </svg>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <svg width="15" height="49" viewBox="0 0 15 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L14.4945 49L14.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center">
            <img src="@/assets/img/icon-competence-5.svg" alt="" />
            <h3 class="title">COMPÉTENCES NUMÉRIQUES</h3>
            <p class="regular">
              Votre capacité à utiliser la technologie et les outils numériques pour trouver, gérer, appliquer, créer et partager des renseignements et du
              contenu.
            </p>
            <a href="#" @click.prevent="showpop()">Évaluez vos compétences</a>
          </div>
          <div class="right">
            <svg width="14" height="49" viewBox="0 0 14 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L2.16492 49L14.0001 24.5055L2.17046 0.00549316Z" />
            </svg>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <svg width="15" height="49" viewBox="0 0 15 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L14.4945 49L14.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center">
            <img src="@/assets/img/icon-competence-6.svg" alt="" />
            <h3 class="title">CALCUL</h3>
            <p class="regular">
              Votre capacité à trouver, à comprendre et à transmettre des renseignements mathématiques présentés sous forme de mots, de chiffres, de
              symboles et de graphiques.
            </p>
            <a href="#" @click.prevent="showpop()">Évaluez vos compétences</a>
          </div>
          <div class="right">
            <svg width="14" height="49" viewBox="0 0 14 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L2.16492 49L14.0001 24.5055L2.17046 0.00549316Z" />
            </svg>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <svg width="15" height="49" viewBox="0 0 15 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L14.4945 49L14.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center">
            <img src="@/assets/img/icon-competence-7.svg" alt="" />
            <h3 class="title">RÉSOLUTION DE PROBLÈMES</h3>
            <p class="regular">Votre capacité à cerner, à analyser, à proposer des solutions et à prendre des décisions.</p>
            <a href="#" @click.prevent="showpop()">Évaluez vos compétences</a>
          </div>
          <div class="right">
            <svg width="14" height="49" viewBox="0 0 14 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L2.16492 49L14.0001 24.5055L2.17046 0.00549316Z" />
            </svg>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <svg width="15" height="49" viewBox="0 0 15 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L14.4945 49L14.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center">
            <img src="@/assets/img/icon-competence-8.svg" alt="" />
            <h3 class="title">LECTURE</h3>
            <p class="regular">
              Votre capacité à trouver, à comprendre et à utiliser les renseignements présentés sous forme de mots, de symboles et d’images.
            </p>
            <a href="#" @click.prevent="showpop()">Évaluez vos compétences</a>
          </div>
          <div class="right">
            <svg width="14" height="49" viewBox="0 0 14 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L2.16492 49L14.0001 24.5055L2.17046 0.00549316Z" />
            </svg>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <svg width="15" height="49" viewBox="0 0 15 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L14.4945 49L14.5 24.5055V0.00549617Z" />
            </svg>
          </div>
          <div class="center">
            <img src="@/assets/img/icon-competence-9.svg" alt="" />
            <h3 class="title">RÉDACTION</h3>
            <p class="regular">Votre capacité à partager des renseignements à l’aide de mots écrits, de symboles et d’images.</p>
            <a href="#" @click.prevent="showpop()">Évaluez vos compétences</a>
          </div>
          <div class="right">
            <svg width="14" height="49" viewBox="0 0 14 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L2.16492 49L14.0001 24.5055L2.17046 0.00549316Z" />
            </svg>
          </div>
        </div>
      </div>
    </section>

    <section id="about" class="partenaires">
      <h3 class="title" data-inview="fadeInUp" data-delay="100">LE COLLÈGE LA CITÉ TRAVAILLE EN PARTENARIAT AVEC</h3>
      <div class="row_logos">
        <div class="logo" data-inview="fadeInUp" data-delay="100">
          <img src="@/assets/img/logo-la-cite.jpg" alt="Logo La Cité" />
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/logo-college-mathieu.jpg" alt="Logo College Mathieu" />
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/logo-educacentre.jpg" alt="Logo Collège Éducacentre" />
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/logo-ccnb.jpg" alt="Logo CCNB" />
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="500">
          <img src="@/assets/img/logo-factry.jpg" alt="Logo The Factry Science and Creativity Center" />
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="600">
          <img src="@/assets/img/logo-skyhive.jpg" alt="Logo SkyHive" />
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="700">
          <img src="@/assets/img/logo-skills-group.jpg" alt="Logo Essential Skills Group" />
        </div>
      </div>
      <div class="canada_wrap" data-inview="fadeInUp" data-delay="700">
        <img src="@/assets/img/logo-canada.svg" alt="Logo Canada" />
        <p class="regular">Ce projet est financé en partie par le Gouvernement du Canada par le biais du Programme Compétences pour réussir.</p>
      </div>
    </section>

    <footer class="footer">
      <div class="question_wrap">
        <div class="wrap">
          <h4 class="title" data-inview="fadeInUp" data-delay="200">Des questions ?</h4>
          <a href="#" class="cta" data-inview="fadeInUp" data-delay="400" @click.prevent="showpop()">
            <div class="left">
              <svg viewBox="0 0 22 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.5 0.00549617L0.011076 0L11.8352 24.5L0 48.9945L21.4945 49L21.5 24.5055V0.00549617Z" fill="#FFE932" />
              </svg>
            </div>
            <div class="center"><p>Contactez-nous</p></div>
            <div class="right">
              <svg viewBox="0 0 21 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.17046 0.00549316L0.511082 0L0.5 24.5L0.500006 48.9945L9.16492 49L21.0001 24.5055L9.17046 0.00549316Z" fill="#FFE932" />
              </svg>
            </div>
          </a>
          <img class="graphic" src="@/assets/img/graphic.svg" alt="Compétences pour réussir" />
        </div>
        <svg class="logo" viewBox="0 0 240 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 32.9654V21.4774C0 15.2242 3.37834 11.8458 9.16754 11.8458C14.9567 11.8458 18.3744 15.2242 18.5868 21.4774H12.5891C12.42 18.7756 11.4054 17.6744 9.12428 17.6744C7.30729 17.6744 6.25328 18.8581 6.25328 21.4774V32.9654C6.25328 35.4982 7.26796 36.682 9.12428 36.682C11.4486 36.682 12.5026 35.5847 12.6717 32.9654H18.6694C18.5435 39.1754 15.1219 42.5105 9.12035 42.5105C3.11877 42.5105 0 39.1715 0 32.9654Z"
            fill="white"
          />
          <path
            d="M22.095 32.4148V22.0241C22.095 15.6882 25.6857 11.9284 31.6833 11.9284C37.681 11.9284 41.2717 15.6882 41.2717 22.0241V32.4148C41.2717 38.7506 37.681 42.5105 31.6833 42.5105C25.6857 42.5105 22.095 38.7506 22.095 32.4148ZM35.0617 32.4148V22.0241C35.0617 19.2357 33.9211 17.7569 31.6833 17.7569C29.4455 17.7569 28.305 19.2357 28.305 22.0241V32.4148C28.305 35.2032 29.4455 36.6819 31.6833 36.6819C33.9211 36.6819 35.0617 35.2032 35.0617 32.4148Z"
            fill="white"
          />
          <path
            d="M71.0986 12.4358V42.0071H65.2268V34.7391C65.396 31.1484 65.5218 27.5577 65.6909 23.967L65.4785 23.8844L62.816 31.6557L61.3372 35.6673H55.4222L53.9434 31.6557L51.2809 23.8844L51.0685 23.967C51.1943 27.5577 51.3634 31.1484 51.4893 34.7391V42.0071H45.6608V12.4358H51.7017L56.3464 23.6287C56.9363 25.1075 57.951 27.6835 58.2892 28.7808H58.4584C58.7966 27.6835 59.8113 25.1075 60.4012 23.6287L65.0459 12.4358H71.0868H71.0986Z"
            fill="white"
          />
          <path
            d="M94.6683 22.4882C94.6683 28.7808 91.0343 32.5013 84.9108 32.5013H82.3348V42.0071H76.3372V12.4358H84.9108C91.0343 12.4358 94.6683 16.1523 94.6683 22.4882ZM88.5016 22.4882C88.5016 19.6172 87.2352 18.1384 84.8282 18.1384H82.3348V26.7986H84.8282C87.2352 26.7986 88.5016 25.3199 88.5016 22.4882Z"
            fill="white"
          />
          <path d="M113.637 36.3005V42.0031H97.9641V12.4358H113.216V18.1384H103.962V24.2619H112.37V29.7522H103.962V36.3005H113.637Z" fill="white" />
          <path d="M154.928 36.3005V42.0031H139.255V12.4358H154.503V18.1384H145.253V24.2619H153.658V29.7522H145.253V36.3005H154.928Z" fill="white" />
          <path
            d="M159.022 12.4358H164.937L170.553 25.2766C171.273 27.1369 171.949 29.0797 172.665 30.936L172.791 30.8102C172.284 27.0936 171.945 22.0241 171.945 19.279V12.4358H177.86V42.0071H171.945L166.962 30.5585L164.3 23.3338L164.174 23.4596C164.638 27.1762 164.933 32.7098 164.933 36.0055V42.0031H159.018V12.4358H159.022Z"
            fill="white"
          />
          <path
            d="M181.455 32.9654V21.4774C181.455 15.2242 184.834 11.8458 190.623 11.8458C196.412 11.8458 199.83 15.2242 200.042 21.4774H194.044C193.875 18.7756 192.861 17.6744 190.579 17.6744C188.762 17.6744 187.705 18.8581 187.705 21.4774V32.9654C187.705 35.4982 188.719 36.682 190.579 36.682C192.904 36.682 193.958 35.5847 194.127 32.9654H200.125C199.999 39.1754 196.577 42.5105 190.579 42.5105C184.582 42.5105 181.455 39.1715 181.455 32.9654Z"
            fill="white"
          />
          <path d="M219.262 36.3005V42.0031H203.589V12.4358H218.837V18.1384H209.587V24.2619H217.995V29.7522H209.587V36.3005H219.262Z" fill="white" />
          <path
            d="M221.543 33.3862H227.71C227.71 35.7538 228.894 36.7645 230.793 36.7645C232.693 36.7645 233.751 35.624 233.751 34.1452C233.751 29.2449 222.176 28.6549 222.176 19.6565C222.176 15.1376 225.346 11.8419 230.793 11.8419C236.24 11.8419 239.536 15.0944 239.536 20.5454H233.369C233.369 18.6025 232.48 17.6311 230.75 17.6311C229.189 17.6311 228.256 18.4767 228.256 19.8296C228.256 24.4782 240 24.7732 240 34.1924C240 39.0495 236.578 42.597 230.707 42.597C224.835 42.597 221.539 39.3445 221.539 33.3862"
            fill="white"
          />
          <path
            d="M106.074 70.725H103.962V80.6516H97.9641V51.0802H106.538C112.41 51.0802 115.874 54.7142 115.874 60.9242C115.874 64.9358 114.396 67.8933 111.776 69.4586L117.353 80.6516H110.805L106.074 70.725ZM103.962 65.0223H106.455C108.567 65.0223 109.708 63.5868 109.708 60.9242C109.708 58.2617 108.567 56.7829 106.455 56.7829H103.962V65.0184V65.0223Z"
            fill="#A1C9DC"
          />
          <path d="M135.857 74.9489V80.6555H120.185V51.0842H135.433V56.7868H126.182V62.9103H134.591V68.4046H126.182V74.9489H135.857Z" fill="#A1C9DC" />
          <path
            d="M139.13 71.2323V51.0842H145.253V71.2323C145.253 73.8949 146.307 75.3304 148.423 75.3304C150.539 75.3304 151.589 73.8949 151.589 71.2323V51.0842H157.712V71.2323C157.712 77.4423 154.248 81.1589 148.419 81.1589C142.59 81.1589 139.126 77.4423 139.126 71.2323"
            fill="#A1C9DC"
          />
          <path
            d="M161.319 72.0346H167.486C167.486 74.4022 168.669 75.4129 170.569 75.4129C172.469 75.4129 173.527 74.2724 173.527 72.7936C173.527 67.8933 161.952 67.3034 161.952 58.3049C161.952 53.786 165.122 50.4903 170.569 50.4903C176.016 50.4903 179.312 53.7428 179.312 59.1938H173.145C173.145 57.2509 172.256 56.2795 170.526 56.2795C168.964 56.2795 168.032 57.1251 168.032 58.474C168.032 63.1227 179.776 63.4177 179.776 72.8369C179.776 77.694 176.354 81.2415 170.482 81.2415C164.611 81.2415 161.315 77.989 161.315 72.0307"
            fill="#A1C9DC"
          />
          <path
            d="M182.525 72.0346H188.692C188.692 74.4022 189.875 75.4129 191.775 75.4129C193.675 75.4129 194.733 74.2724 194.733 72.7936C194.733 67.8933 183.158 67.3034 183.158 58.3049C183.158 53.786 186.328 50.4903 191.775 50.4903C197.222 50.4903 200.518 53.7428 200.518 59.1938H194.351C194.351 57.2509 193.462 56.2795 191.732 56.2795C190.17 56.2795 189.238 57.1251 189.238 58.474C189.238 63.1227 200.982 63.4177 200.982 72.8369C200.982 77.694 197.56 81.2415 191.689 81.2415C185.817 81.2415 182.521 77.989 182.521 72.0307"
            fill="#A1C9DC"
          />
          <path d="M211.026 51.0842H205.029V80.6555H211.026V51.0842Z" fill="#A1C9DC" />
          <path
            d="M231.418 72.6442L229.83 69.4586C232.449 67.8933 233.928 64.9397 233.928 60.9242C233.928 54.7142 230.463 51.0802 224.591 51.0802H216.017V80.6516H222.015V70.725H224.127L226.589 75.8888C227.977 78.7992 230.911 80.6516 234.132 80.6516H239.221V74.9056H235.064C233.519 74.9056 232.103 74.0286 231.415 72.6442M224.504 65.0223H222.011V56.7868H224.504C226.616 56.7868 227.757 58.2223 227.757 60.9282C227.757 63.634 226.616 65.0262 224.504 65.0262"
            fill="#A1C9DC"
          />
          <path
            d="M83.499 74.3078C85.607 74.3078 86.8498 75.5231 86.8498 77.576V78.4373H90.0315V80.447H80.1285V77.576C80.1285 75.5231 81.3713 74.3078 83.4951 74.3078M83.4951 76.3726C82.5315 76.3726 82.036 76.7973 82.036 77.6036V78.4373H84.9345V77.6036C84.9345 76.7973 84.439 76.3726 83.4911 76.3726"
            fill="white"
          />
          <path
            d="M86.8222 73.3167H83.3416C81.2218 73.3167 79.9594 72.1133 79.9594 70.1036C79.9594 68.0939 81.2179 66.8904 83.3416 66.8904H86.8222C88.946 66.8904 90.2045 68.0939 90.2045 70.1036C90.2045 72.1133 88.946 73.3167 86.8222 73.3167ZM86.8222 68.9748H83.3416C82.4095 68.9748 81.914 69.3563 81.914 70.1075C81.914 70.8587 82.4095 71.2402 83.3416 71.2402H86.8222C87.7543 71.2402 88.2499 70.8587 88.2499 70.1075C88.2499 69.3563 87.7543 68.9748 86.8222 68.9748Z"
            fill="white"
          />
          <path
            d="M86.8774 65.5926H80.1285V63.5396H86.8774C87.7701 63.5396 88.2499 63.1856 88.2499 62.4777C88.2499 61.7698 87.7701 61.4158 86.8774 61.4158H80.1285V59.3629H86.8774C88.9578 59.3629 90.2006 60.5231 90.2006 62.4738C90.2006 64.4245 88.9578 65.5847 86.8774 65.5847"
            fill="white"
          />
          <path
            d="M86.1694 55.0682V55.7761H89.4927V57.7858H79.5897V54.9148C79.5897 52.9483 80.805 51.7881 82.8855 51.7881C84.2305 51.7881 85.2177 52.2837 85.7447 53.1607L89.4927 51.2926V53.4832L86.1694 55.0682ZM84.2581 55.7761V54.9423C84.2581 54.2344 83.7782 53.8529 82.8855 53.8529C81.9927 53.8529 81.4972 54.2344 81.4972 54.9423V55.7761H84.2541H84.2581Z"
            fill="white"
          />
          <path d="M109.251 7.61012L112.893 0H100.406L96.7645 7.61012H109.251Z" fill="#FFE932" />
          <path d="M123.268 27.0267H129.435V18.1384H135.22V12.4358H117.479V18.1384H123.268V27.0267Z" fill="white" />
          <path d="M131.464 46.2585H118.977L122.619 38.6484L118.977 31.0383H131.464L135.11 38.6484L131.464 46.2585Z" fill="#FFE932" />
          <path
            d="M106.074 70.725H103.962V80.6516H97.9641V51.0802H106.538C112.41 51.0802 115.874 54.7142 115.874 60.9242C115.874 64.9358 114.396 67.8933 111.776 69.4586L117.353 80.6516H110.805L106.074 70.725ZM103.962 65.0223H106.455C108.567 65.0223 109.708 63.5868 109.708 60.9242C109.708 58.2617 108.567 56.7829 106.455 56.7829H103.962V65.0184V65.0223Z"
            fill="#A1C9DC"
          />
          <path d="M135.857 74.9489V80.6555H120.185V51.0842H135.433V56.7868H126.182V62.9103H134.591V68.4046H126.182V74.9489H135.857Z" fill="#A1C9DC" />
          <path
            d="M139.13 71.2323V51.0842H145.253V71.2323C145.253 73.8949 146.307 75.3304 148.423 75.3304C150.539 75.3304 151.589 73.8949 151.589 71.2323V51.0842H157.712V71.2323C157.712 77.4423 154.248 81.1589 148.419 81.1589C142.59 81.1589 139.126 77.4423 139.126 71.2323"
            fill="#A1C9DC"
          />
          <path
            d="M161.319 72.0346H167.486C167.486 74.4022 168.669 75.4129 170.569 75.4129C172.469 75.4129 173.527 74.2724 173.527 72.7936C173.527 67.8933 161.952 67.3034 161.952 58.3049C161.952 53.786 165.122 50.4903 170.569 50.4903C176.016 50.4903 179.312 53.7428 179.312 59.1938H173.145C173.145 57.2509 172.256 56.2795 170.526 56.2795C168.964 56.2795 168.032 57.1251 168.032 58.474C168.032 63.1227 179.776 63.4177 179.776 72.8369C179.776 77.694 176.354 81.2415 170.482 81.2415C164.611 81.2415 161.315 77.989 161.315 72.0307"
            fill="#A1C9DC"
          />
          <path
            d="M182.525 72.0346H188.692C188.692 74.4022 189.875 75.4129 191.775 75.4129C193.675 75.4129 194.733 74.2724 194.733 72.7936C194.733 67.8933 183.158 67.3034 183.158 58.3049C183.158 53.786 186.328 50.4903 191.775 50.4903C197.222 50.4903 200.518 53.7428 200.518 59.1938H194.351C194.351 57.2509 193.462 56.2795 191.732 56.2795C190.17 56.2795 189.238 57.1251 189.238 58.474C189.238 63.1227 200.982 63.4177 200.982 72.8369C200.982 77.694 197.56 81.2415 191.689 81.2415C185.817 81.2415 182.521 77.989 182.521 72.0307"
            fill="#A1C9DC"
          />
          <path d="M211.026 51.0842H205.029V80.6555H211.026V51.0842Z" fill="#A1C9DC" />
          <path
            d="M231.418 72.6442L229.83 69.4586C232.449 67.8933 233.928 64.9397 233.928 60.9242C233.928 54.7142 230.463 51.0802 224.591 51.0802H216.017V80.6516H222.015V70.725H224.127L226.589 75.8888C227.977 78.7992 230.911 80.6516 234.132 80.6516H239.221V74.9056H235.064C233.519 74.9056 232.103 74.0286 231.415 72.6442M224.504 65.0223H222.011V56.7868H224.504C226.616 56.7868 227.757 58.2223 227.757 60.9282C227.757 63.634 226.616 65.0262 224.504 65.0262"
            fill="#A1C9DC"
          />
        </svg>
      </div>
      <div class="bottom">
        <p class="regular">© Tous droits réservés - La Cité</p>
        <div class="social">
          <a href="https://www.facebook.com/competencespourreussir" target="_blank">
            <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="6" y="6" width="17" height="19" fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM16.5634 15.6589V23.8197H13.1869V15.6592H11.4999V12.8469H13.1869V11.1585C13.1869 8.86425 14.1394 7.5 16.8457 7.5H19.0987V10.3126H17.6904C16.6369 10.3126 16.5672 10.7056 16.5672 11.4391L16.5634 12.8466H19.1147L18.8161 15.6589H16.5634Z"
                fill="#2F4F7E"
              />
            </svg>
          </a>
          <a href="https://www.linkedin.com/company/competences-pour-reussir/" target="_blank">
            <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="6" y="6" width="18" height="19" fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM7.20099 12.4237H10.6003V22.6373H7.20099V12.4237ZM10.8242 9.26419C10.8021 8.26275 10.086 7.5 8.92308 7.5C7.7602 7.5 6.99994 8.26275 6.99994 9.26419C6.99994 10.2449 7.73772 11.0296 8.87896 11.0296H8.90068C10.086 11.0296 10.8242 10.2449 10.8242 9.26419ZM18.9457 12.1838C21.1826 12.1838 22.8596 13.6439 22.8596 16.7811L22.8595 22.6372H19.4603V17.173C19.4603 15.8005 18.9684 14.8639 17.7378 14.8639C16.7987 14.8639 16.2393 15.4953 15.9936 16.1051C15.9037 16.3237 15.8817 16.6282 15.8817 16.9334V22.6375H12.4819C12.4819 22.6375 12.5267 13.3823 12.4819 12.4239H15.8817V13.8706C16.3328 13.1752 17.1409 12.1838 18.9457 12.1838Z"
                fill="#2F4F7E"
              />
            </svg>
          </a>
        </div>
      </div>
    </footer>

    <div class="pop_wrap" :class="{ active: popIsOpen }">
      <div class="pop">
        <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidepop()" />
        <p class="text">Étape 1 :</p>
        <p class="regular">Testez vos compétences pour obtenir un rapport de résultat.</p>
        <a href="https://xn--comptences-pour-russir-e8bn.ca/" target="_blank">Plateforme d'évaluation</a>
        <p class="text">Étape 2 :</p>
        <p class="regular">Développez vos connaissances dans les neuf compétences pour réussir.</p>
        <a href="https://lacitecc.brightspace.com/program/12/competences-pour-reussir" target="_blank">Plateforme d'apprentissage</a>
      </div>
      <div class="overlay" @click.prevent="hidepop()"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Flickity from 'vue-flickity'
import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: `Développez vos compétences avec les Compétences pour réussir`
      },
      link: [
        {
          rel: 'canonical',
          href: 'https://competencespourreussir.com/'
        }
      ],
      meta: [
        {
          name: 'description',
          content: `Le programme Compétences pour Réussir (CPR) est une initiative du gouvernement du Canada destinée aux travailleurs...`,
          id: 'desc'
        },
        {
          name: 'application-name',
          content: `Développez vos compétences avec les Compétences pour réussir`
        },
        {
          name: 'twitter:title',
          content: `Le programme Compétences pour Réussir (CPR) est une initiative du gouvernement du Canada destinée aux travailleurs...`
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Le programme Compétences pour Réussir (CPR) est une initiative du gouvernement du Canada destinée aux travailleurs...`
        },
        // Facebook / Open Graph
        {
          property: 'og:title',
          content: `Développez vos compétences avec les Compétences pour réussir`
        },
        {
          property: 'og:site_name',
          content: `Développez vos compétences avec les Compétences pour réussir`
        },
        {
          property: 'og:description',
          content: `Le programme Compétences pour Réussir (CPR) est une initiative du gouvernement du Canada destinée aux travailleurs...`
        },
        // Og Image
        {
          p: 'og:image',
          c: '/img/og-img.jpg'
        },
        {
          name: 'twitter:image',
          content: '/img/og-img.jpg'
        }
      ]
    }
  },

  data() {
    return {
      popIsOpen: false
    }
  },

  methods: {
    showpop() {
      this.popIsOpen = true
    },
    hidepop() {
      this.popIsOpen = false
    },
    toogleNavBarStatus() {
      const st = document.documentElement.scrollTop

      if (!document.querySelector('.header')) {
        return
      }

      if (st >= 60) {
        document.querySelector('.header').classList.add('sticky')
      } else {
        document.querySelector('.header').classList.remove('sticky')
      }
    }
  },

  computed: mapState('app', ['appTitle']),

  created() {
    // NavBar Follow
    this.toogleNavBarStatus()
    window.addEventListener('scroll', () => {
      this.toogleNavBarStatus()
    })
  },

  mounted() {
    setTimeout(() => {
      const heroCarousel = new Flickity('.hero-carousel', {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        cellAlign: 'left',
        autoPlay: 3000,
        pauseAutoPlayOnHover: false
      })

      setTimeout(() => {
        heroCarousel.resize()
      }, 100)

      // on change
      const heroTitle = document.querySelector('.hero .title_black')
      heroCarousel.on('select', () => {
        const currentSlide = heroCarousel.selectedIndex
        const currentSlideHeroText = document.querySelector(`.hero-carousel .slide:nth-child(${currentSlide + 1})`).getAttribute('data-hero-text')

        document.querySelectorAll('.hero-carousel .slide').forEach(slide => {
          slide.classList.remove('next-in-line')
        })

        const nextInLine = document.querySelector(`.hero-carousel .slide:nth-child(${currentSlide + 1})`)

        if (!nextInLine) {
          document.querySelector(`.hero-carousel .slide:nth-child(1)`).classList.add('next-in-line')
        }

        nextInLine.classList.add('next-in-line')

        heroTitle.innerHTML = currentSlideHeroText
      })

      const infosSlider = new Flickity('.infos-slider', {
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true
      })

      setTimeout(() => {
        infosSlider.resize()
      }, 100)
    }, 100)
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/landing.scss';
@import '@/theme/animations-layer.scss';
</style>
